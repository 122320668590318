<template>
  <div class="photoTrap">
    <LoadingPlaceholder v-if="preparing == true" />

    <template v-if="groupedPhotos && groupedPhotos.length > 0">
      <div
        id="nav-tab"
        class="nav nav-tabs"
        role="tablist"
      >
        <a
          v-for="(key, index) in groupedPhotos"
          :id="`spoT${ index }-tab`"
          :key="`Phototrap-tab-listItem-${ index }`"
          :class="['nav-item nav-link', {'active': index == 0}]"
          data-toggle="tab"
          :href="`#spoT${ index }`"
          role="tab"
          :aria-controls="`spoT${ index }`"
          :aria-selected="index === 0"
        >Name: {{ key[3] }}, Spot: {{ key[0] }}</a>
      </div>
      <div
        id="nav-tabContent"
        class="tab-content"
      >
        <div
          v-for="(vals, index) in groupedPhotos"
          :id="`spoT${ index }`"
          :key="`Phototrap-session-listItem-${ index }`"
          :class="['tab-pane fade show', {'active': index == 0}]"
          role="tabpanel"
          :aria-labelledby="`spoT${ index }-tab`"
        >
          <SessionOverview
            :runs="vals[1]"
            :display-properties="['createDate', 'card']"
            :loading="loading"
            :filter="filter"
            :installation-type="installationType"
            :installation-id="installationId"
            @loadSessions="loadSessions"
          />
        </div>
      </div>
    </template>

    <p v-else-if="preparing == false && (groupedPhotos == null || groupedPhotos.length == 0)">
      No data available.
    </p>

    <SweetModal
      :id="modalId"
      ref="photoStartmodal"
      title="Session"
      width="75%"
      @closeModal="closeModal"
    >
      <template
        slot="box-action"
      >
        <toggle-button
          v-model="viewDecoratedPhotos"
          :labels="{ checked: 'decorated', unchecked: 'normal' }"
          :width="100"
          :height="30"
          :font-size="12"
          class="mr-3 mt-2"
        />
      </template>
      <sweet-modal-tab
        id="tab1"
        title="Photo"
      >
        <img
          v-if="altImageAvailable"
          id="img"
          :src="altImageUrl"
          class="img-fluid"
        >
        <ImagePlaceholderSvg
          v-else
          class="img-fluid"
        />
      </sweet-modal-tab>

      <sweet-modal-tab
        id="tab2"
        title="Details"
      >
        <table
          v-if="payload != null"
          class="defaultTable"
        >
          <colgroup>
            <col width="200">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>Id</th>
              <td class="lineBreaks">
                {{ payload.id }}
              </td>
            </tr>
            <tr>
              <th>Card Number</th>
              <td class="lineBreaks">
                {{ payload.identificationId }}
              </td>
            </tr>
            <tr>
              <th>Create date</th>
              <td v-tooltip="dateTime_fromNow(payload.created)">
                {{ dateTime_dateTime(payload.created) }}
              </td>
            </tr>
            <tr>
              <th>Image Url</th>
              <td>{{ payload.imageUrl }}</td>
            </tr>
            <tr>
              <th>Snapshot Url</th>
              <td>{{ payload.snapshotUrl }}</td>
            </tr>
            <tr>
              <th>Spot Id</th>
              <td>{{ payload.spotId }}</td>
            </tr>
          </tbody>
        </table>
      </sweet-modal-tab>
      <sweet-modal-tab
        id="tab3"
        title="EXIF"
      >
        <pre v-if="exifData">{{ exifData }}</pre>
        <template v-else>
          {{ $t('noDataAvailable') }}
        </template>
      </sweet-modal-tab>
    </SweetModal>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { SweetModal, SweetModalTab } from 'sweet-modal-vue';

export default {
  name: 'PhotoStart',
  components: {
    ImagePlaceholderSvg: () => import('@/components/Base/ImagePlaceholderSvg.vue'),
    SessionOverview: () => import('@/components/Media/SessionOverview.vue'),
    SweetModal,
    SweetModalTab
  },
  mixins: [
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    installationType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      useAlternativeFilter: true,
      loading: true,
      groupedPhotos: null,
      altImageUrl: "",
      modalId: 'photostartModal',
      filter: {
        dates: null,
        cardNumber: null,
        startDate: null,
        endDate: null,
        showIncomplete: true,
        limit: 100
      },
      preparing: true,
      payload: null,
      viewDecoratedPhotos: true,
      exifData: null
    }
  },
  computed: {
    areTherePhotos: function () {
      if (this.groupedPhotos && this.groupedPhotos.length > 0) return true;
      return false;
    },
    altImageAvailable: function () {
      if (this.payload && this.altImageUrl && this.altImageUrl.length > 0) {
        return true;
      }
      return false;
    } 
  },
  watch: {
    viewDecoratedPhotos (val) {
      if(this.altImageAvailable) {
        this.altImageUrl = val ? this.decoratedUrl(this.payload.id) : this.payload.imageUrl;
      }
    }
  },
  created () {
    let tmpDate = new Date(Date.now());
    this.filter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 1, 0, 0, 0);
    this.filter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), 23, 59, 59);
    this.filter.dates = [];
    this.filter.dates.push(this.filter.startDate, this.filter.endDate);

    this.loadSessions();
    this.$eventBus.$on(`openModal${this.modalId}`, this.openModal);
  },
  beforeDestroy () {
    this.$eventBus.$off(`openModal${this.modalId}`, this.openModal);
  },
  methods: {
    decoratedUrl (runId) {
      return "https://api.skiline.cc/php/app/api-helper/0.10/Admin/Video/decoratedRun.php?runId="+runId+"&apiKey=383zhdd7yJHDOcvmiq563wnmdjuhJ83wdsn";
    },
    openModal (payload) {
      if (payload) {
        this.getExifData(payload);
        this.payload = payload;
        if(this.viewDecoratedPhotos) {
          this.altImageUrl = this.decoratedUrl(payload.id);
        } 
        else {
          this.altImageUrl = payload.imageUrl;
        }
      }
      this.$refs.photoStartmodal.open();
    },
    closeModal () {
      this.payload = null;
      this.altImageUrl = '';
      this.exifData = null;
      this.$refs.photoStartmodal.close();
    },
    // GET DEVICE gets the data for the device specified by the deviceId
    loadSessions (payload) {
      if (payload == null || this.useAlternativeFilter == true) {
        payload = {
          limit: 100,
          dates: [this.useAlternativeFilter == true ? null : this.filter.startDate, this.useAlternativeFilter == true ? null : this.filter.endDate]
        }
      }
      this.loading = true;
      this.axios.get(`/Media/GetPhototrap/${ this.installationId }/${ payload.limit }/${ this.dateTime_isoString(payload.dates[0]) }/${ this.dateTime_isoString(payload.dates[1]) }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data.length === 0) {
            return;
          }
          let grouped = response.data.reduce((h, obj) => Object.assign(h, { [obj.spotId]:( h[obj.spotId] || [] ).concat(obj) }), {})
          this.groupedPhotos = Object.entries(grouped);
          this.groupedPhotos.forEach(e => {
            let camId = e[1][0].camId;
            let camName = e[1][0].camName;
            e.push(camId);
            e.push(camName);
          });
          
          this.groupedPhotos.sort(function (a, b) {
            return a[2] - b[2];
          });  
        })
        .finally(() => {
          this.loading = false;
          window.setTimeout(() => {
            this.preparing = false;
          }, 500);
        });
    },
    getExifData (payload) {
      if(payload.imageUrl) {
        var urlObj = {
          url: payload.imageUrl
        };

        this.axios.post(`/Media/GetExifData`, urlObj)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.exifData = JSON.stringify(JSON.parse(response.data),null,2);
        });
      }
    }
  }
}
</script>